@import '../../styles/_colors';
@import '../../styles/_font_styles';
.header {
  box-shadow: 0 0 4px 4px #00000014;
  position: sticky !important;
  top: 0;
  z-index: 1;
  width: 100%;
}

.lang-select {
  .lang-text {
    @extend .arabic-font;
    transform: translateY(-0.4rem);
  }
}

.lang-select-overlay {
  .ant-popover-inner {
    padding-left: 0;
    padding-right: 0;
  }
  ul {
    list-style: none;
    li {
      @extend .arabic-font;
      padding-left: 1.3rem;
      padding-right: 1.3rem;
      span {
        transform: translateY(-0.4rem);
      }
      &:hover {
        background-color: $Bright-Gray;
      }
    }
  }
}
