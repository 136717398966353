$offWhite: #f5f5f5;
$hoverMenu: #1677ff;
$dark-blue: #001529;
$darkBlack: #3b3b3b;
$darkBlack-lighter: #424649;
$darkBlack-flash: #4d5154;
$subtitleGrey: #4a4a4a;
$inputLabelGrey: #454545;
$greenGrey-darker: #8b9f9c;
$greenGrey-darker: #8b9f9c;
$disabledGrey: #f3f3f3;
$copyrightGray: #858e8d;
$greenFooter: #0c1e1b;
$greenFooter: #0c1e1b;
$gull-Grey: #9aafac;
$grey-soft: #cdd5d2;
$grey-strip: #f2f6f6;
$greenGrey: #35594c;
$kellyGreen: #0adfba;
$kellyGreen-lighter: #22d8ba;
$kellyGreen-flash: #57e7cf;
$kellyGreen-soft: #d0faed;
$kellyGreen-success: #eefdfa;
$lightWhite: #ffffff;
$lightWhite-darker: #e7e5e5;
$lightWhite-flash: #d4d1d1;
$offWhiteLight: #edf0ef;
$dangerRed: #ff3921;
$dangerRed-light: #fff0ee;

// Turquoise  palette
$Bright-Gray: #ececec;
$Sea-Foam-Green: #9fd3c7;
$Police-Blue: #385170;
$Space-Cadet: #142d4c;
