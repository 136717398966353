@import '../../styles/_colors';
@import '../../styles/_font_styles';

.upload {
  width: 5rem;
  height: 5rem;
  position: relative;
}

.image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.input {
  display: none;
}

.camera-icon {
  background-image: url('/assets/icons/camera.png');
  height: 24px;
  width: 24px;
  position: absolute;
  bottom: 0;
  right: 1.5%;
  cursor: pointer;
}
