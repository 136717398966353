@import '../../styles/_colors';
@import '../../styles/_font_styles';

.scoll-row-horizontal {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  display: block !important;
  .col {
    display: inline-block !important;
    width: auto;
    max-width: none;
  }
}

.pointer {
  cursor: pointer;
}

.table {
  border-collapse: collapse;
  background: white;
  border-radius: 15px;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
}

.table-head {
  height: 50px;
  background: $Space-Cadet;
  th {
    vertical-align: middle;
    color: white;
  }
}

.tbody {
  tr {
    color: gray;
    &:nth-child(even) {
      background-color: #e4dddd;
    }
    &:hover {
      color: #555;
      background-color: #f5f5f5;
      cursor: pointer;
    }
    td {
      padding: 0.7rem 1rem;
    }
  }
}
