@import '../../styles/_colors';
@import '../../styles/_font_styles';

.screenLoader {
  z-index: 50;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  &::before {
    content: '';
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $darkBlack-flash;
    opacity: 0.4;
  }
}
