@import '../../../styles/_colors';
@import '../../../styles/_font_styles';

.bg-kelly-green {
  opacity: 1;
  background-color: $kellyGreen !important;
}

.select-control-badge {
  @extend .arabic-font;
  @extend .fs-xx-sm;
  @extend .f-w-bold;
  top: 1.1rem !important;
  width: 23px;
  height: 23px;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
}
[dir='rtl'] {
  .select-control-badge {
    right: 90% !important;
  }
  .bade-text-ps {
    transform: translate(20%, 10%);
  }
}
[dir='ltr'] {
  .select-control-badge {
    left: 90% !important;
  }
  .bade-text-ps {
    transform: translate(-20%, 10%);
  }
}
