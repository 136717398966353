@import '~bootstrap/scss/mixins/_breakpoints.scss';
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '../../styles/_colors';

.avatar-section {
  background-color: $dark-blue;
  color: white;
  height: 12rem;
}

.about-card {
  position: relative;
  @include media-breakpoint-up(md) {
    bottom: 2rem;
  }
  @include media-breakpoint-down(sm) {
    bottom: 1rem;
  }
}
